.hoox-ingridients {
    padding: 48px 0;
}
.hoox-ingridients__wrapper {
    background: var(--hoox-color-yellow);
    padding: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.hoox-ingridients__title {
    margin-bottom: 16px;
}
.hoox-ingridients__image {
    display: none;
}
.hoox-ingridients__image-mob {
    height: 220px;
    margin-bottom: 8px;
}
.hoox-ingridients__icon {
    display: none;
}
.hoox-ingridients__props {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    row-gap: 18px;
}
.hoox-ingridients__prop {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    row-gap: 15px;
}
.hoox-ingridients__prop-name {
    font-size: 20px;
    line-height: 26px;
    color: var(--hoox-color-slate-secondary);
}
.hoox-ingridients__prop-description {}

@media (min-width: 768px) {
    .hoox-ingridients {
        padding: 68px 0;
    }
    .hoox-ingridients__wrapper {
        padding: 48px;
        min-height: 494px;
    }
        .hoox-ingridients__props {
            width: 824px;
            display: flex;
            flex-wrap: wrap;
            padding-top: 30px;
            flex-direction: row;
            justify-content: space-between;
            row-gap: 30px;
    }
    .hoox-ingridients__prop {
        width: 300px;
    }
    .hoox-ingridients__prop-name {
        font-size: 24px;
        line-height: 31px;
    }
    .hoox-ingridients__prop:first-of-type {
        align-items: flex-end;
        text-align: right;
    }
    .hoox-ingridients__prop:nth-of-type(3), .hoox-ingridients__prop:nth-of-type(5), .hoox-ingridients__prop:nth-of-type(7) {
        align-items: flex-end;
        text-align: right;
    }
    .hoox-ingridients__title {
        max-width: unset;
        margin-bottom: 40px;
    }
    .hoox-ingridients__icon {
        position: absolute;
        left: 30px;
        bottom: 30px;
        display: block;
    }
    .hoox-ingridients__image-mob {
        display: none;
    }
    .hoox-ingridients__image {
        display: block;
        width: 144px;
        height: 310px;
        position: absolute;
        top: 136px;
        left: 50%;
        transform: translateX(-50%);
    }
}

@media (max-width: 767px) {
    .hoox-ingridients__prop-description br {
        display: none;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation:portrait){
    .hoox-ingridients__props {
        width: 650px;
    }
    .hoox-ingridients__prop {
        width: 250px;
    }
}